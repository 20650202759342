import {createActionGroup, props} from '@ngrx/store'
import { IPhone } from '../../models/shared/phoneNumber'

export const PhoneVerificationActions = createActionGroup({
    source: 'Phone Verification',
    events:{
        'Phone Number Verification': props<{phoneNumber:string, index:number}>(),
        'Phone Numbers Verification': props<{phoneNumbers: IPhone[]}>(),
        'Employee Phone Numbers Verification': props<{phoneNumbers: IPhone[]}>(),
        'Site Phone Numbers Verification': props<{phoneNumbers: IPhone[]}>()

    }
})

export const PhoneVerificationAPIActions = createActionGroup({
    source: 'Phone Verification API',
    events:{
        'Phone Verification Success': props<{phone: IPhone }>(),
        'Phone Verification Fail' : props<{message: string}>(),
        'Phones Verification Success': props<{phones: IPhone[] }>(),
        'Phones Verification Fail' : props<{message: string}>(),
        'Employee Phones Verification Success': props<{phones: IPhone[] }>(),
        'Employee Phones Verification Fail' : props<{message: string}>(),
        'Site Phones Verification Success': props<{phones: IPhone[] }>(),
        'Site Phones Verification Fail' : props<{message: string}>()
    }
})