import {createActionGroup, emptyProps, props} from '@ngrx/store'
import { IEmail } from '../../models/shared/email'

export const EmailVerificationAction = createActionGroup({
    source: 'Email Verification',
    events:{
        'Email Verification': props<{emailAddress: string, index:number}>(),
        'Emails Verification': props<{emails: IEmail[]}>(),
        'Employee Emails Verification': props<{emails: IEmail[]}>(),
        'Site Emails Verification': props<{emails: IEmail[]}>()
    }
})

export const EmailVerificationActionAPI = createActionGroup({
    source: 'Email Verification API',
    events:{
        'Email Verification Success': props<{email: IEmail }>(),
        'Email Verification Fail' : props<{message: string}>(),
        'Emails Verification Success': props<{emails: IEmail[] }>(),
        'Emails Verification Fail' : props<{message: string}>(),
        'Employee Emails Verification Success': props<{emails: IEmail[] }>(),
        'Employee Emails Verification Fail' : props<{message: string}>(),
        'Site Emails Verification Success': props<{emails: IEmail[] }>(),
        'Site Emails Verification Fail' : props<{message: string}>()
    }
})